export default [
  //   "admin","manager","super-manager","process-manager","hr","accountant","super-accountant","markter"
  {
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'fa-solid fa-house',
    role: [
      'admin',
      'manager',
      'super-manager',
      'hr',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },
  {
    title: 'Accounting',
    icon: 'fa-solid fa-receipt',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
    children: [
      {
        title: 'Accounts Tree',
        route: 'accounting',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'Park Entries',
        route: 'Park Entries',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'Post Entries',
        route: 'Post Entries',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
    ],
  },
  {
    title: 'Analysis',
    route: 'analysis',
    icon: 'fa-solid fa-chart-gantt',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'USER SITE',
    route: 'user-site',
    icon: 'fa-solid fa-user',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager'],
  },
  {
    title: 'USER PANEL',
    route: 'user-panel',
    icon: 'fa-regular fa-user',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager'],
  },
  {
    title: 'New Employees',
    route: 'employees',
    icon: 'fa-solid fa-user-tie',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'hr'],
  },
  // {
  //   /// ////// devices ///////////
  //   title: 'Assets',
  //   route: 'devices',
  //   icon: 'fa-solid fa-desktop',
  //   action: 'browes',
  //   role: ['admin', 'manager', 'super-manager'],
  // },
  {
    title: 'Offer',
    route: 'offer',
    icon: 'fa-solid fa-money-bill',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'markter'],
  },
  // {
  //   title: 'Engineers Courses',
  //   route: 'engineers-courses',
  //   icon: 'fa-solid fa-graduation-cap',
  //   action: 'browes',
  //   role: ['admin', 'manager', 'super-manager', 'hr'],
  // },
  {
    title: 'Task Management',
    icon: 'fa-solid fa-bars-progress',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
    children: [
      {
        title: 'My Created Tasks',
        route: 'Task Management Created',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'My Assigned Tasks',
        route: 'Task Management Assigned',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
    ],
  },
  {
    title: 'Eng. Assign Setup',
    route: 'engineers',
    icon: 'fa-solid fa-user-gear',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Requests',
    icon: 'fa-solid fa-code-pull-request',
    route: 'requests',
    role: [
      'admin',
      'manager',
      'super-manager',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },
  {
    title: 'Requests Over Due',
    icon: 'fa-solid fa-exclamation',
    route: 'requests-over-due',
    role: [
      'admin',
      'manager',
      'super-manager',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },

  {
    title: 'Departments',
    route: 'departments',
    icon: 'fa-solid fa-industry',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager'],
  },
  {
    title: 'certificate',
    route: 'main-certificate',
    icon: 'fa-certificate',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'office',
    route: 'office',
    icon: 'fa-solid fa-building',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
  {
    title: 'Delegator',
    route: 'delegator',
    icon: 'fa-solid fa-user-tag',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },
  {
    title: 'Audit Set Money',
    route: 'set-money',
    icon: 'fa-solid fa-money-check',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
  {
    title: 'Audit Assigned',
    route: 'audit-assigned',
    icon: 'fa-regular fa-building',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Audit Not Assigned',
    route: 'audit-not-assigned',
    icon: 'fa-regular fa-building',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'All Service',
    route: 'all-service',
    icon: 'fa-brands fa-servicestack',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Service Need Approval',
    route: 'service-need-approval',
    icon: 'fa-solid fa-person-circle-check',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Service Need Set Money',
    route: 'service-need-set-money',
    icon: 'fa-solid fa-money-check',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
  {
    title: 'Facility',
    route: 'facility',
    icon: 'fa-solid fa-landmark-flag',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
  {
    title: 'Schedule',
    route: 'schedule',
    icon: 'fa-regular fa-calendar-days',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'hr'],
  },
  {
    title: 'Report',
    route: 'report',
    icon: 'fa-solid fa-diagram-project',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Source Account',
    route: 'source-account',
    icon: 'fa-regular fa-address-card',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
]
